// initialize popups
const popups = [];

function Popup(id, element, image) {
  this.id = id || null;
  this.element = element || null;
  this.active = false;
  this.image = image || null;

  this.setActive = function() {
    if (!this.active) {
      this.active = true;
      this.element.classList.add('active');
    } else {
      this.active = false;
      this.element.classList.remove('active');
    }
  }
  this.setImage = function(image) {
    if (this.element) {
      const imageContainer = this.element.querySelector('.popup__image');
      if (imageContainer) {
        let img = imageContainer.querySelector('img');
        if (!img) {
          img = document.createElement('img');
          imageContainer.appendChild(img);
        }
        img.setAttribute('src', image);
        img.setAttribute('alt', image);
      }
      const downloadButton = this.element.querySelector('.popup__download');
      downloadButton.setAttribute('href', image);
    }
  }
  this.setCloseEvent = function() {
    if (this.element) {
      const closeBtn = this.element.querySelector('.popup__close');
      if (closeBtn) {
        closeBtn.addEventListener('click', () => {
          this.setActive();
        })
      }
      const bg = this.element.querySelector('.popup__background');
      if (bg) {
        bg.addEventListener('click', () => {
          this.setActive();
        })
      }
    }
  };
  this.setCloseEvent();
}

const pagePopups = document.querySelectorAll('.popup');
pagePopups.forEach((popup) => {
  if (popup) {
    popups.push(new Popup(popup.id, popup));
  }
})

// initialize triggers
const triggers = document.querySelectorAll('[data-popup]');

triggers.forEach((trigger) => {
  addAction(trigger);
})

function addAction(trigger) {
  trigger.addEventListener('click', () => {
    const popup = popups.find((el) => el.id === trigger.dataset.popup);
    popup.setActive();
    popup.setImage(trigger.dataset.image);
  })
}