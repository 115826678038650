// initialize popups
const sorteoPopups = [];

function SorteoPopup(id, element, image) {
  this.id = id || null;
  this.element = element || null;
  this.active = false;
  
  this.setActive = function() {
    if (!this.active) {
      this.active = true;
      this.element.classList.add('active');
    } else {
      this.active = false;
      this.element.classList.remove('active');
      $("#popup_sorteovivo").hide();
    }
  }
  this.setCloseEvent = function() {
    if (this.element) {
      const closeBtn = this.element.querySelector('.sorteo-popup__close');
      if (closeBtn) {
        closeBtn.addEventListener('click', () => {
          this.setActive();
        })
      }
    }
  };
  this.setCloseEvent();
}

const pagPopups = document.querySelectorAll('.sorteo-popup');
pagPopups.forEach((popup) => {
  if (popup) {
    sorteoPopups.push(new SorteoPopup(popup.id, popup));
  }
})

sorteoPopups.forEach((popup) => {
  setTimeout(() => {
    popup.setActive();
  }, 1000);
})
