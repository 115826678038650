// Navbar
// Desde aqui se maneja el cambio de estilos de la barra de navegación

var navbar;
var main;

function setMainMargin() {
  navbar = document.querySelector('.superior');
  main = document.querySelector('.main');
  main.style.marginTop = navbar.clientHeight + 'px';
  // console.log(navbar.clientHeight + 'px')
}

setMainMargin();
$(window).on('resize', function() {
  setMainMargin();
});

$(".superior").on('contentchanged', function() {
  setMainMargin();
});

window.addEventListener('resize', () => {
  // console.log('redimension')
  setMainMargin();
});

$(".superior").on('resize',function() {
  console.log('redimension')
  setMainMargin();
});

// Scroll Event Listener
window.onscroll = () => {
  // Navbar
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  if (scrollTop > 0) {
    navbar.classList.add('active');
  } else {
    navbar.classList.remove('active');
  }
};

// Boton Mobile Menu
const mobileMenuToggle = document.querySelector('.navbar2-mobile-menu-btn2');
const navbarMenu = document.querySelector('.navbar2-menu__container2');
const navbarMenuBackground = document.querySelector('.navbar2-menu__background2');
let navbarMenuActive = false;

const toggleMenu = () => {
  navbarMenuActive = !navbarMenuActive;
  if (navbarMenuActive) { 
    navbarMenu.classList.add('active');
  } else {
    navbarMenu.classList.remove('active');
  }
};

mobileMenuToggle.addEventListener('click', toggleMenu);
navbarMenuBackground.addEventListener('click', toggleMenu);


