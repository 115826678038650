// aca irian todos los css
require('../frontend/fontawesome/all.min.css')
require('../frontend/css/bootstrap.min.css')
require('../frontend/css/animate.css')
require('../frontend/css/main.css')
require('../frontend/css/tiny-slider.css')
require('../frontend/css/custom.css?v1')
require('../frontend/video/video-js.min.css')


global.$  = require('jquery')
global.jQuery  = require('jquery')
require('bootstrap')
require('../frontend/js/main.js')
global.moment = require('moment')
require('../frontend/js/popup.js')
require('../frontend/js/sorteo-popup.js')




// require('../frontend/js/bootstrap.min.js');
// global.tns = require('../frontend/js/tiny-slider.js');
// require('../frontend/js/main.js');
// require('../frontend/js/moment.js');
// require('../frontend/js/moment-with-locales.js');
// require('../frontend/js/popup.js');
// require('../frontend/js/sorteo-popup.js');
// require('../frontend/js/jquery.lazy.min.js');
// require('../frontend/js/jquery.lazy.plugins.min.js');

